<template>
    <div class="page message-page justify-content--sm--start">
        <Header/>
        <div class="row">
            <div class="col col--sm--12" v-for="(notification, index) in notifications" :key="index">
                <div class="message" :class="[notification.isRead ? 'message--is-read' : '']" v-on:click="notificationClickHandle({sonumber: notification.sonumber, id: notification.id})">
                    <div class="marked-is-read d--sm--flex align-items--sm--center justify-content--sm--end" v-if="!notification.isRead"><span v-on:click="readNotificationClickHandle(notification.id)">Đánh dấu là đã đọc</span><CheckReadedIcon class="ml--sm--2"/></div>
                    <div class="h5 font-weight--sm--500 mb--sm--3" v-if="notification.title">{{notification.title}}</div>
                    <div class="mb--sm--3" v-if="notification.body">{{notification.body}}</div>
                    <div class="text--sm--right"><span v-if="notification.createDate && notification.createTime">{{$moment(notification.createDate.concat(notification.createTime), 'YYYYMMDDHHmmss').format('DD/MM/YYYY HH:mm')}}</span></div>
                </div>
            </div>
            <infinite-loading @infinite="infiniteHandler"><div slot="no-more"></div><div slot="no-results"></div></infinite-loading>
            <div class="col col--sm--12 text--sm--center d--sm--flex justify-content--sm--center align-items--sm--center flex-direction--sm--column" v-if="isEmpty">
                <NoMessageIcon class="mb--sm--2"/>
                <div class="mb--sm--12 mb--md--20">Bạn không có thông báo mới !</div>
                <HdButton>
                    Quay về trang chủ
                    <template v-slot:icon><ArrowRightIcon/></template>
                </HdButton>
            </div>
        </div>
        <Footer/>
        <FooterMobile/>
    </div>
</template>

<script>
// Import Components
import HdButton from '@/components/HdButton'
import Header from '@/components/Header.vue'
import Footer from '@/components/Footer.vue'
import FooterMobile from '@/components/FooterMobile.vue'

// Import Icons
import NoMessageIcon from '@/assets/no-message.svg'
import ArrowRightIcon from '@/assets/arrow-right.svg'
import CheckReadedIcon from '@/assets/check-readed.svg'

import InfiniteLoading from 'vue-infinite-loading'

export default {
  name: 'Notification',
  components: {
    Header,
    Footer,
    FooterMobile,
    HdButton,
    NoMessageIcon,
    ArrowRightIcon,
    CheckReadedIcon,
    InfiniteLoading
  },
  methods: {
    infiniteHandler: function ($state) {
      this.$store.dispatch('user/getTotalNotifications').then(() => {
        if (this.pagination.currentPage <= (this.pagination.total / this.pagination.pageSize)) {
          this.$store.dispatch('user/getNotification').then(() => {
            $state.loaded()
            // Set curent page after load
            this.pagination.currentPage += 1

            // Check if is last of pages.
            if (this.pagination.currentPage >= (this.pagination.total / this.pagination.pageSize)) {
              $state.complete()
            }
          })
        } else {
          $state.complete()
        }
      })
    },
    readNotificationClickHandle: function (id) {
      this.$store.dispatch('user/readNotification', { id }).then(() => {
        this.$store.dispatch('user/getNotification')
      })
    },
    notificationClickHandle: function ({ id, sonumber }) {
      this.$store.dispatch('user/readNotification', { id }).then(() => {
        this.$store.dispatch('user/getNotification')
        this.$router.push(`/order-history/${sonumber}`).catch(() => {})
      })
    }
  },
  computed: {
    pagination: {
      get () {
        return this.$store.state.user.pagination
      },
      set (pagination) {
        this.$store.commit('user/setPagination', pagination)
      }
    },
    isEmpty () {
      return this.$_.isEmpty(this.$store.state.user.notifications)
    },
    notifications () {
      return this.$store.state.user.notifications
    }
  },
  mounted () {
    this.$store.dispatch('setHeaderMobile', {
      heading: true,
      headingText: 'Thông báo',
      back: true,
      search: false,
      notify: false,
      cart: true
    })
    this.$store.dispatch('setFooterMobile', false)
  }
}
</script>

<style lang="scss" scoped>
  .message-page {
    overflow: hidden;
  }
    .message {
        padding: 20px;
        border-bottom: 1px solid #59C6BC;
        transition: all 0.3s;
        cursor: pointer;
        &--is-read {
          background-color: #ffffff;
        }
        &:hover {
          opacity: 0.75;
        }
    }
    .marked-is-read {
        font-size: 14px;
        font-weight: 500;
        color: #B93D00;
        margin-bottom: 14px;
        cursor: pointer;
        transition: all 0.3s;
        &:hover {
            opacity: 0.75;
        }
        &:active {
            opacity: 0.5;
        }
    }
</style>
